<template>
  <div class="Home">
    <div class="banner">
      <van-swipe :autoplay="5000" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in banners" :key="index">
          <div class="img-wrapper">
            <img class="banner-img" :src="item" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <NavBar :activeNav="'home'" />
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import banner1 from '../assets/banner01.jpg';
import banner2 from '../assets/banner02.jpg';
import banner3 from '../assets/banner03.jpg';

Vue.use(Swipe).use(SwipeItem);

export default {
  name: 'Home',
  components: {
    NavBar,
  },
  data() {
    return {
      banners: [banner1, banner2, banner3],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
@import '../styles/vars';

.Home {
  padding-bottom: 0.58rem;

  .banner {
    min-height: 2.64rem;

    .img-wrapper {
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
