<template>
  <van-tabbar class="nav-bar" v-model="active">
    <van-tabbar-item name="home" to="/home" icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item name="profile" to="/profile" icon="user-o"
      >个人中心</van-tabbar-item
    >
  </van-tabbar>
</template>

<script>
import Vue from "vue";
import { Icon, Tabbar, TabbarItem } from "vant";

Vue.use(Icon).use(Tabbar).use(TabbarItem);
export default {
  name: "NavBar",
  props: ["activeNav"],
  data() {
    return {
      active: this.activeNav,
    };
  },
};
</script>

<style lang="scss">
@import "../styles/vars";

.nav-bar {
  height: 0.58rem;

  .van-tabbar-item--active {
    color: $primary;
  }
}
</style>
