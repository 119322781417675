// Note: API接口数据
// import axios from "axios";
// 测试
// const baseURL = "https://hroapitest.zanhua.com.cn";
// const baseURL = "http://127.0.0.1:57777";
// const baseURL = "http://192.168.111.68:57777";
// 正式
// const baseURL = "https://hroapi2.zanhua.com.cn";
// 实验兼容两者
import request from "./request";
const baseURL = process.env.VUE_APP_URL
export function fetchList(start, length, modulePath, data) {
  if (data) {
    // 构建查询字符串
    let query = "?";
    for (const key in data) {
      query += `${key}=${data[key]}&`;
    }
    // 去掉最后一个&
    query = query.slice(0, -1);
    return request.get(baseURL + modulePath + `/list/${start}/${length}${query}`);
  } else {
    return request.get(baseURL + modulePath + `/list/${start}/${length}`);
  }
}
export function fetchPage(number, length, modulePath) {
  return request.get(baseURL + modulePath + `/page/${number}/${length}`);
}
export function fetchDetail(id, modulePath) {
  return request.get(baseURL + modulePath + `/detail/${id}`);
}

export function create(data, modulePath) {
  return request.post(baseURL + modulePath + "/create", data);
}
export function createBatch(data, modulePath) {
  return request.post(baseURL + modulePath + "/create/batch", data);
}
export function update(data, modulePath) {
  return request.put(baseURL + modulePath + "/update", data);
}
export function updateBatch(data, modulePath) {
  return request.put(baseURL + modulePath + "/update/batch", data);
}
export function remove(id, modulePath) {
  return request.delete(baseURL + modulePath + `/remove/${id}`);
}
export function removeBatch(data, modulePath) {
  return request.delete(baseURL + modulePath + "/remove/batch", { data });
}
export function postPage(data, modulePath) {
  return request.post(baseURL + modulePath + "/post/page", data);
}
export function postDetail(data, modulePath) {
  return request.post(baseURL + modulePath + "/post/detail", data);
}
export function postUpdate(data, modulePath) {
  return request.post(baseURL + modulePath + "/post/update", data);
}
export function postRemove(data, modulePath) {
  return request.post(baseURL + modulePath + "/post/remove", data);
}
// 发布
export function postPublish(id, modulePath) {
  return request.put(baseURL + modulePath + `/publish/${id}`);
}
// 模版下载
// export function downloadTemplates(positionNo) {
//   if (positionNo) {
//     return request.get(
//       `${baseURL}/tax/entuser/task/download/template?positionNo=${positionNo}`
//     );
//   } else {
//     return request.get(`${baseURL}/tax/entuser/task/download/template`);
//   }
// }
// 同步上传
export function postUpload(data, modulePath) {
  return request.post(baseURL + modulePath + "/upload", data);
}
// 异步上传
export function postUploadAsync(data, modulePath) {
  return request.post(baseURL + modulePath + "/upload/asyn", data);
}
// 同步下载
export function postDownload(data, modulePath) {
  return request.post(baseURL + modulePath + "/download", data);
}
// 异步下载
export function postDownloadAsync(data, modulePath) {
  return request.post(baseURL + modulePath + "/download/asyn", data);
}
// 旧版同步上传
export function postUploadOld(data, modulePath) {
  return request.post(baseURL + modulePath + "/old/upload", data);
}
// 旧版异步上传
export function postUploadAsyncOld(data, modulePath) {
  return request.post(baseURL + modulePath + "/old/upload/asyn", data);
}
// 旧版同步下载
export function postDownloadOld(data, modulePath) {
  return request.post(baseURL + modulePath + "/old/download", data);
}
// 旧版异步下载
export function postDownloadAsyncOld(data, modulePath) {
  return request.post(baseURL + modulePath + "/old/download/asyn", data);
}
// get方法导出
export function fetchDownload(data, modulePath) {
  if (data) {
    // 构建查询字符串
    let query = "?";
    for (const key in data) {
      query += `${key}=${data[key]}&`;
    }
    // 去掉最后一个&
    query = query.slice(0, -1);
    return request.get(baseURL + modulePath + `/download/asyn${query}`);
  } else {
    return request.get(baseURL + modulePath + `/download/asyn`);
  }
}

export function disabled(id, modulePath) {
  return request.disabled(baseURL + modulePath + `/disabled/${id}`);
}

export function fetchPutDownload(data, modulePath) {
  if (data) {
    // 构建查询字符串
    let query = "?";
    for (const key in data) {
      query += `${key}=${data[key]}&`;
    }
    // 去掉最后一个&
    query = query.slice(0, -1);
    return request.get(baseURL + modulePath + `/download/output/asyn${query}`);
  } else {
    return request.get(baseURL + modulePath + `/download//output/asyn`);
  }
}