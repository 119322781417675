<template>
  <div class="Agreement">
    <template>
      <div class="content">
        <p class="title">“飞腾兔”平台注册服务协议</p>
        <p>亲爱的用户</p>
        <p style="text-indent: 21pt">
          欢迎您（即本协议项下的“用户”）与上海琪驭精工科技有限公司签署《飞腾兔平台注册服务协议》（以下简称“本协议”），感谢您选用“飞腾兔”品牌，通过“飞腾兔”平台/软件、互联网及线下向您提供的各项服务。
        </p>
        <p style="text-indent: 21pt">
          您访问本网站、使用本网站的任何服务、在本网站内与其他用户的交流互动、在本网站内发布任何信息或内容以及使用其他用户发布的任何信息或内容等一切与本网站有关的活动和行为，均受到以下协议条款的约束和限制，请您务必仔细阅读。&nbsp;特别是采用粗体字标注的免除或限制责任的条款、法律使用和争议解决条款。
        </p>
        <p style="text-indent: 21pt">
          【特别提示】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您以其他“飞腾兔”允许的方式实际使用“飞腾兔”平台服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与“飞腾兔”平台达成协议。您承诺接受并遵守本协议的所有约定，届时您不应以未阅读本协议的内容或者未获得飞腾兔对您问询的解答等理由，主张本协议无效，或要求撤销本协议。本网站有权随时变更本协议并在本网站上予以公告。经修订的条款一经在本网站的公布后，立即自动生效。如您不同意相关变更，必须停止使用本网站。若您对本协议的条款或其他变更内容，存在任何疑问，请您立刻联系飞腾兔平台工作人员澄清，若您不同意本协议，您应立即停止注册或停止使用飞腾兔平台以及平台服务。
        </p>
        <p>一、协议范围</p>
        <p>
          1、本协议由您和“飞腾兔”平台（域名为“https://wap.zanhua.com.cn/”、“飞腾兔微信小程序”或其他H5等开放平台,以下简称“飞腾兔”平台；）运营方上海琪驭精工科技有限公司订立，本协议具有合同效力。
        </p>
        <p>
          2、除另行明确声明外，“飞腾兔”平台服务包含任何“飞腾兔”平台及其关联公司、第三方服务商提供的基于互联网以及移动互联网的相关服务，且均受本协议约束。如果您不同意本协议的约定，您应立即停止注册或停止使用“飞腾兔”平台服务。
        </p>
        <p>
          3、本协议内容包括协议正文、法律声明、《“飞腾兔”平台服务规则》及所有“飞腾兔”平台已经发布或将来可能发布的各类规则、公告或通知（以下合称“‘飞腾兔’平台规则”或“规则”）。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。
        </p>
        <p>
          4、“飞腾兔”平台有权依据国家法律法规调整的要求和公平交易的商业原则及时地制订、修改本协议及各类规则，并以网站公示的方式进行变更公告，无需另行单独通知您。变更后的协议和规则一经在网站公布后，立即或在公告明确的特定时间自动生效。若您在前述变更公告后继续使用“飞腾兔”平台服务的，即表示您已经阅读、理解并接受经修订的协议和规则。若您不同意相关变更，应当立即停止使用“飞腾兔”平台服务。
        </p>
        <p>二、注册与账户</p>
        <p>1、主体资格</p>
        <p>
          您确认，在您完成注册程序或以其他“飞腾兔”平台允许的方式实际使用“飞腾兔”平台服务时，您应当符合下列条件之一:
        </p>
        <p>（1）年满十八岁，并具有民事权利能力和民事行为能力的自然人；</p>
        <p>
          （2）无民事行为能力人或限制民事行为能力人应在其监护人的监护下使用“飞腾兔”平台服务；
        </p>
        <p>
          （3）根据中国法律、法规、行政规章成立并合法存在的机关、企事业单位、个体工商户、社团组织和其他组织。
        </p>
        <p>2、用户的帐号、密码和安全性</p>
        <p>
          您一旦注册成功成为用户，您将得到一个密码和帐号。如果您未保管好自己的帐号和密码而对您、“飞腾兔”平台或第三方造成的损害，您将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。您可随时改变您的密码和图标，也可以结束旧的帐户重开一个新帐户。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，立即通告“飞腾兔”平台。
        </p>
        <p>三、“飞腾兔”平台服务</p>
        <p>
          1、您可以在“飞腾兔”平台上发布需求/服务、查询需求/服务、达成交易意向并进行交易、对其他会员进行评价、参加“飞腾兔”平台组织的活动以及使用其它信息服务及技术服务，具体以所开通的平台提供的服务内容为准。
        </p>
        <p>
          2、您在“飞腾兔”平台上交易过程中与其他会员发生交易纠纷时，一旦您或其它会员任一方或双方共同提交“飞腾兔”平台要求调查处理，则“飞腾兔”平台作为独立第三方，有权根据单方判断做出调查处理决定，您了解并同意接受“飞腾兔”平台的判断和调查处理决定。
        </p>
        <p>
          3、您了解并同意，“飞腾兔”平台有权应政府部门（包括司法及行政部门）的要求，向其提供您向“飞腾兔”平台提供的用户信息和交易记录等必要信息。如您涉嫌侵犯他人知识产权等合法权益，则“飞腾兔”平台亦有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供您必要的身份信息。
        </p>
        <p>
          4、您应当自行承担因交易产生或取得的相关费用，并依法纳税，平台不承担代扣义务。
        </p>
        <p>5、拒绝提供担保和免责声明</p>
        <p>
          您明确同意使用“飞腾兔”平台服务的风险由您个人承担。服务提供是建立在免费的基础上。“飞腾兔”平台明确表示不提供任何类型的担保，不论是明确的或隐含的，但是对商业性的隐含担保，特定目的和不违反规定的适当担保除外。“飞腾兔”平台不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性、安全性、真实性、出错发生都不作担保。“飞腾兔”平台拒绝提供任何担保，包括信息能否准确、及时、顺利地传送。用户理解并接受下载或通过“飞腾兔”平台产品服务取得的任何信息资料取决于用户自己，并由其承担系统受损、资料丢失以及其它任何风险。“飞腾兔”平台对在服务网上得到的任何商品购物服务、交易进程、招聘信息，承包过程、成果审核等都不作担保。用户不会从“飞腾兔”平台收到口头或书面的意见或信息，“飞腾兔”平台也不会在这里作明确担保。
        </p>
        <p>四、“飞腾兔”平台服务使用规范</p>
        <p>
          1、在“飞腾兔”平台上使用“飞腾兔”平台服务过程中，你承诺遵守以下约定：
        </p>
        <p>
          （1）实施的所有行为均遵守国家法律、法规等规范性文件及“飞腾兔”平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不偷逃应缴税费，不违反本协议及相关规则。
        </p>
        <p>
          （2）在与其他会员交易过程中，遵守诚实信用原则，不采取不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为。
        </p>
        <p>
          （3）不发布国家禁止销售的或限制销售的服务信息（除非取得合法且足够的许可），不发布涉嫌侵犯他人知识产权或其它合法权益的服务信息，不发布违背社会公共利益或公共道德或“飞腾兔”平台认为不适合在“飞腾兔”平台上销售的服务信息，不发布其它涉嫌违法或违反本协议及各类规则的信息。
        </p>
        <p>
          （4）不采取不正当手段（包括但不限于虚假需求、互换好评等方式）提高自身或他人评价，或采用不正当手段恶意评价其他用户，降低其他人评价。
        </p>
        <p>
          （5）未经公司书面许可，不对“飞腾兔”平台上的任何数据作商业性利用，包括但不仅限于在未经“飞腾兔”平台事先书面同意的情况下，以复制、传播等任何方式使用“飞腾兔”平台站上展示的资料。
        </p>
        <p>
          （6）不使用任何装置、软件或例行程序干预或试图干预“飞腾兔”平台的正常运作或正在“飞腾兔”平台上进行的任何交易、活动。你不得采取任何将导致不合理的庞大数据负载加诸“飞腾兔”平台网络设备的行为。
        </p>
        <p>2、您了解并同意：</p>
        <p>
          （1）您如果违反前述承诺，产生任何法律后果的，您应以自己的名义独立承担所有的法律责任，并确保“飞腾兔”平台免于因此产生任何损失或增加费用。
        </p>
        <p>
          （2）基于维护“飞腾兔”平台交易秩序及交易安全的需要，“飞腾兔”平台有权在发生恶意交易等扰乱市场正常交易秩序的情形下，执行强制关闭相应交易订单等操作。
        </p>
        <p>
          （3）&nbsp;经国家行政或司法机关的生效法律文书确认你存在违法或侵权行为，或者“飞腾兔”平台根据自身的判断，认为您的行为涉嫌违反法律法规的规定或涉嫌违反本协议和/或规则的条款的，则“飞腾兔”平台有权在“飞腾兔”平台上公示您的该等涉嫌违法或违约行为及“飞腾兔”平台已对您采取的措施。
        </p>
        <p>
          （4）对于您在“飞腾兔”平台上发布的涉嫌违法、涉嫌侵犯他人合法权利或违反本协议和规则的信息，“飞腾兔”平台有权不经通知你即予以删除，且按照规则的规定进行处罚。
        </p>
        <p>
          （5）对于您违反本协议项下承诺，或您在“飞腾兔”平台上实施的行为，包括你未在“飞腾兔”平台上实施但已经对“飞腾兔”平台及其用户产生影响的行为，“飞腾兔”平台有权单方认定您行为的性质及是否构成对本协议规则的违反，并根据单方认定结果适用规则予以处理或终止向您提供服务，且无须征得您的同意或提前通知予您。您应自行保存与您行为有关的全部证据，并应对无法提供充要证据而承担的不利后果。
        </p>
        <p>
          （6）如您涉嫌违反有关法律或者本协议之规定，使“飞腾兔”平台遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿“飞腾兔”平台因此造成的损失发生的费用，包括合理的律师费用。
        </p>
        <p>3、您了解并同意：</p>
        <p>
          您单独承担发布内容的责任。您对服务的使用是根据所有适用于服务的地方法律、国家法律和国际法律标准的。您承诺:
        </p>
        <p>
          （1）在“飞腾兔”平台的网页上发布信息或者利用“飞腾兔”平台的服务时必须符合中国有关法规，不得在“飞腾兔”平台的网页上或者利用“飞腾兔”平台的服务制作、复制、发布、传播以下信息:
        </p>
        <p>（a）反对宪法所确定的基本原则的;</p>
        <p>（b）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>（c）损害国家荣誉和利益的；</p>
        <p>（d）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（e）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>（f）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>（g）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>（h）侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>（i）含有法律、行政法规禁止的其他内容的。</p>
        <p>
          （2）在“飞腾兔”平台的网页上发布信息或者利用“飞腾兔”平台的服务时还必须符合其他有关国家和地区的法律规定以及国际法的有关规定。
        </p>
        <p>（3）不利用“飞腾兔”平台的服务从事以下活动:</p>
        <p>（a）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
        <p>（b）未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
        <p>
          （c）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
        </p>
        <p>（d）故意制作、传播计算机病毒等破坏性程序的；</p>
        <p>（e）其他危害计算机信息网络安全的行为。</p>
        <p>（4）不以任何方式干扰“飞腾兔”平台的服务。</p>
        <p>（5）遵守“飞腾兔”平台的所有其他规定和程序。</p>
        <p>
          您需对自己在使用“飞腾兔”平台服务过程中的行为承担法律责任。您理解，如果“飞腾兔”平台发现其网站传输的信息明显属于上段第(1)条所列内容之一，依据中国法律，“飞腾兔”平台有义务立即停止传输，保存有关记录，向国家有关机关报告，并且删除含有该内容的地址、目录或关闭服务器。
        </p>
        <p>
          您使用“飞腾兔”平台电子公告服务，包括电子布告牌、电子白板、电子论坛、网络聊天室和留言板等以交互形式为上网用户提供信息发布条件的行为，也须遵守本条的规定以及“飞腾兔”平台将专门发布的电子公告服务规则，上段中描述的法律后果和法律责任同样适用于电子公告服务的用户。
        </p>
        <p>
          若您的行为不符合以上提到的服务条款，“飞腾兔”平台将作出独立判断立即取消用户服务帐号。
        </p>
        <p>五、特别授权</p>
        <p>您完全理解并不可撤销地授予“飞腾兔”平台及其关联公司下列权利：</p>
        <p>
          1、您完全理解并不可撤销地授权“飞腾兔”平台或“飞腾兔”平台授权的第三方或您与“飞腾兔”平台一致同意的第三方，根据本协议及“飞腾兔”平台规则的规定，处理您在“飞腾兔”平台上发生的所有交易及可能产生的交易纠纷。您同意接受“飞腾兔”平台或“飞腾兔”平台授权的第三方或您与“飞腾兔”平台一致同意的第三方的判断和调查处理决定。该决定将对你具有法律约束力。
        </p>
        <p>
          2、一旦您向“飞腾兔”平台和其关联公司做出任何形式的承诺，且相关公司已确认您违反了该承诺，则“飞腾兔”平台有权立即按您的承诺或协议约定的方式对您的账户采取限制措施，包括中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，“飞腾兔”平台无须就相关确认与您核对事实，或另行征得您的同意，且“飞腾兔”平台无须就此限制措施或公示行为向你承担任何的责任。
        </p>
        <p>
          3、对于您提供的资料及数据信息，您授予“飞腾兔”平台及其关联公司永久免费的许可使用权利(并有权在多个层面对该权利进行再授权)。此外，“飞腾兔”平台及其关联公司有权(全部或部分)使用、复制、修订、改写、发布、翻译、分发、执行和展示您的全部资料数据（包括但不限于注册资料、交易行为数据及全部展示于“飞腾兔”平台的各类信息）或制作其派生作品，并以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。
        </p>
        <p>六、责任范围和责任限制</p>
        <p>
          1、“飞腾兔”平台负责按"现状"和"可得到"的状态向您提供“飞腾兔”平台服务。但“飞腾兔”平台对“飞腾兔”平台服务不作任何明示或暗示的保证，包括但不限于“飞腾兔”平台服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，“飞腾兔”平台也不对“飞腾兔”平台服务所涉及的技术及信息的有效性、准确性、正确性、可靠性、稳定性和及时性做出任何承诺和保证。
        </p>
        <p>
          2、您了解“飞腾兔”平台仅作为您获取需求和服务信息、物色交易对象、就服务的交易进行协商及开展交易的场所，平台上的服务和需求信息系由用户自行发布，且可能存在风险和瑕疵，“飞腾兔”平台无法完全控制交易所涉及的服务的质量、安全或合法性，信息的真实性或准确性，以及交易各方履行其在贸易协议中各项义务的能力。您应自行谨慎判断确定相关信息的真实性、合法性和有效性，并自行承担因此产生的责任与损失。
        </p>
        <p>
          3、除非法律法规明确要求，或出现以下情况，否则，“飞腾兔”平台没有义务对所有用户的信息数据、服务信息、交易行为以及与交易有关的其它事项进行事先审查：
        </p>
        <p>
          （1）“飞腾兔”平台有合理的理由认为特定会员及具体交易事项可能存在重大违法或违约情形。
        </p>
        <p>
          （2）“飞腾兔”平台有合理的理由认为用户在“飞腾兔”平台的行为涉嫌违法或其他不当。
        </p>
        <p>
          4、“飞腾兔”平台或“飞腾兔”平台授权的第三方或您与“飞腾兔”平台一致同意的第三方有权基于您不可撤销的授权受理您与其他会员因交易产生的争议，并有权单方判断与该争议相关的事实及应适用的规则，进而做出处理决定，包括但不限于调整相关订单的交易状态，争议款项的全部或部分支付给交易一方或双方。该处理决定对您有约束力。如你未在限期内执行处理决定的，则“飞腾兔”平台有权利（但无义务）直接使用您账户内的款项，或您向“飞腾兔”平台及其关联公司交纳的保证金代为支付。您应及时补足保证金并弥补“飞腾兔”平台及其关联公司的损失，否则“飞腾兔”平台及其关联公司有权直接抵减您在其它合同项下的权益，并有权继续追偿。
        </p>
        <p>
          您理解并同意，“飞腾兔”平台或“飞腾兔”平台授权的第三方或您与“飞腾兔”平台一致同意的第三方并非司法机构，仅能以普通人的身份对证据进行鉴别，“飞腾兔”平台或“飞腾兔”平台授权的第三方或您与“飞腾兔”平台一致同意的第三方对争议的调查处理完全是基于您不可撤销的授权，其无法保证争议处理结果符合您的期望，也不对争议调处结论承担任何责任。如你因此遭受损失，您同意自行向受益人索偿。
        </p>
        <p>
          5、您了解并同意，“飞腾兔”平台不对因下述任一情况而导致您的任何损害赔偿承担责任，包括但不限于利润、商誉、数据等方面的损失或其它无形损失的损害赔偿(无论“飞腾兔”平台是否已被告知该等损害赔偿的可能性)：
        </p>
        <p>（1）使用或未能使用“飞腾兔”平台服务。</p>
        <p>（2）第三方未经批准的使用您的账户或更改您的数据。</p>
        <p>
          （3）通过“飞腾兔”平台交易服务或获取任何平台增值服务、数据、信息或进行交易等行为或替代行为产生的费用及损失。
        </p>
        <p>（4）您对“飞腾兔”平台服务的误解。</p>
        <p>
          （5）任何非因“飞腾兔”平台的原因而引起的与“飞腾兔”平台服务有关的其它损失。
        </p>
        <p>
          6、不论在何种情况下，“飞腾兔”平台均不对由于信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。
        </p>
        <p>七、协议终止</p>
        <p>
          1、您同意，“飞腾兔”平台有权自行全权决定以任何理由不经事先通知的中止、终止向您提供部分或全部“飞腾兔”平台服务，暂时冻结或永久冻结（注销）您的账户在“飞腾兔”平台的权限，且无须为此向您或任何第三方承担任何责任。
        </p>
        <p>
          2、出现以下情况时，“飞腾兔”平台有权直接以注销账户的方式终止本协议，并有权永久冻结（注销）您的账户在“飞腾兔”平台网的权限和收回账户对应的“飞腾兔”平台昵称：
        </p>
        <p>
          （1）“飞腾兔”平台终止向您提供服务后，您涉嫌再一次直接或间接以他人名义注册为“飞腾兔”平台用户的；
        </p>
        <p>
          （2）您提供的电子邮箱不存在或无法接收电子邮件，且没有任何其他方式可以与您进行联系，或“飞腾兔”平台以其它联系方式通知您更改电子邮件信息，而您在“飞腾兔”平台通知后三个工作日内仍未更改为有效的电子邮箱的。
        </p>
        <p>（3）您提供的用户信息中的主要内容不真实或不准确或不及时或不完整；</p>
        <p>
          （4）本协议（含规则）变更时，您明示并通知“飞腾兔”平台不愿接受新的服务协议的；
        </p>
        <p>（5）其它“飞腾兔”平台认为应当终止服务的情况。</p>
        <p>
          （6）您若反对任何服务条款的建议或对后来的条款修改有异议，或对“飞腾兔”平台服务不满，您只有以下的追索权：
        </p>
        <p>（a）不再使用“飞腾兔”平台服务。</p>
        <p>（b）结束用户使用“飞腾兔”平台服务的资格。</p>
        <p>（c）通告“飞腾兔”平台停止该用户的服务。</p>
        <p>
          结束用户服务后，即协议终止。您使用“飞腾兔”平台服务的权利马上中止。从那时起，“飞腾兔”平台不再对您承担任何义务。
        </p>
        <p>
          3、您的账户服务被终止或者账户在“飞腾兔”平台的权限被永久冻结（注销）后，“飞腾兔”平台没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。
        </p>
        <p>
          4、您同意，您与“飞腾兔”平台的合同关系终止后，“飞腾兔”平台仍享有下列权利：
        </p>
        <p>
          （1）继续保存您的用户信息及您使用“飞腾兔”平台服务期间的所有交易信息。
        </p>
        <p>
          （2）您在使用“飞腾兔”平台服务期间存在违法行为或违反本协议和/或规则的行为的，“飞腾兔”平台仍可依据本协议向您主张权利。
        </p>
        <p>
          5、“飞腾兔”平台中止或终止向您提供“飞腾兔”平台服务后，对于您在服务中止或终止之前的交易行为依下列原则处理，您应独力处理并完全承担进行以下处理所产生的任何争议、损失或增加的任何费用，并应确保“飞腾兔”平台免于因此产生任何损失或承担任何费用：
        </p>
        <p>
          （1）您在服务中止或终止之前已经上传至“飞腾兔”平台的服务交易尚未交易的，“飞腾兔”平台有权在中止或终止服务的同时删除此项服务交易的相关信息；
        </p>
        <p>
          （2）您在服务中止或终止之前已经与其他会员达成服务交易合同，但合同尚未实际履行的，“飞腾兔”平台有权删除该买卖合同及其交易服务的相关信息；
        </p>
        <p>
          （3）您在服务中止或终止之前已经与其他会员达成买卖合同且已部分履行的，“飞腾兔”平台可以不删除该项交易，但“飞腾兔”平台网有权在中止或终止服务的同时将相关情形通知您的交易对方。
        </p>
        <p>八、隐私权政策</p>
        <p>
          “飞腾兔”平台将在“飞腾兔”平台公布并不时修订隐私权政策，隐私权政策构成本协议的有效组成部分。
        </p>
        <p>九、知识产权条款</p>
        <p>
          1、本网站之域名、商标，以及本网站内所有信息内容（本网站用户发布的信息除外），包括但不限于网站架构、文字、图片、软件、音频、视频、用户等级/评价体系/信用体系/榜单等数据信息，以及所有本网站使用之所有技术、数据分析模型、计算机软件、数据库（第三方创建并保留所有权和/或知识产权的除外），其所有权知识产权均归属“飞腾兔”平台唯一所有，未经“飞腾兔”平台书面许可，任何人均不得擅自进行全部和局部复制、转载、引用和链接等使用和处分。
        </p>
        <p>
          2、用户在本网站发布的任何信息和内容（用户隐私信息除外），包括但不限于文字、图片、软件、音频、视频等，均被视为永久、免费且不可撤销地许可“飞腾兔”平台自行或许可其关联方、业务合作方、宣传方不受任何限制的使用和处分。
        </p>
        <p>
          3、任何单位或者个人不得以任何方式引诱本网站用户或者第三方复制转载本网站之信息内容，或者同意该用户或者第三方复制转载本网站内容。
        </p>
        <p>
          4、任何违反本站知识产权声明的行为，本站保留进一步追究法律责任的权利。
        </p>
        <p>十、法律适用、管辖与其他</p>
        <p>
          1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律，如无相关法律规定的，则应参照通用国际商业惯例或行业惯例。
        </p>
        <p>
          2、因本协议产生之争议需根据您使用的服务归属的平台确定具体的争议对象，因您使用“飞腾兔”平台服务所产生的争议应由“飞腾兔”平台的经营者与您直接沟通并处理。一旦产生不可调和的争议，您与“飞腾兔”平台的经营者均同意该争议统一归“飞腾兔”平台平台所在地相应级别的法院管辖。
        </p>
        <p>3、本协议以其中文版本为最终生效版本。</p>
        <p>签署声明：</p>
        <p>
          您已经充分知悉且已理解本协议全部内容，保证网络流程操作者已获有效授权并足以代表自己或者授权人订立本协议。
        </p>
      </div>
    </template>
  </div>
</template>
  
  <script>
export default {
  name: "Agreement",
  created() {},
};
</script>
  
  <style lang="scss" scoped>
@import "../styles/vars";
.Agreement {
  .content {
    max-height: 3.2rem;
    margin: 0.2rem 0;
    padding: 0 0.2rem;
    font-size: 0.12rem;
    text-align: justify;
    overflow-y: auto;

    p {
      margin: 0.2rem 0;
      font-size: 0.14rem;
      &.title {
        margin: 0;
        padding: 0;
        color: $font;
        font-size: 0.16rem;
        text-align: center;
      }
    }
  }
}
</style>
  