function getQueryParams(search) {
  let params = {};
  if (!search) {
    return params;
  }

  params = search.slice(1,).split('&').reduce((prev, cur) => {
    let [key, value] = cur.split('=');
    prev[key] = value;
    return prev;
  }, params);

  return params;
}

export default getQueryParams;
