<template>
  <div class="Authentication">
    <div class="title">请拍摄并上传您的有效身份证</div>

    <div class="upload-wrapper">
      <div class="item item-front" @click="chooseFileFront">
        <van-uploader ref="cardFront" :after-read="afterRead1">
          <div class="inner">
            <img v-if="!image1" src="../assets/auth_front.png" alt="" />
            <img v-else class="uploaded-img" :src="image1" alt="" />
            <p>身份证人像面</p>
          </div>
        </van-uploader>
      </div>
      <div class="item item-back" @click="chooseFileBack">
        <van-uploader ref="cardBack" :after-read="afterRead2">
          <div class="inner">
            <img v-if="!image2" src="../assets/auth_back.png" alt="" />
            <img v-else class="uploaded-img" :src="image2" alt="" />
            <p>身份证国徽面</p>
          </div>
        </van-uploader>
      </div>
    </div>

    <van-divider />

    <div class="upload-tips">
      <p><b>拍摄身份证要求：</b></p>
      <p>大陆公民持有的本人有效二代身份证；</p>
      <p>拍摄时确保身份证<span>边框完整，字体清晰，亮度均匀；</span></p>
      <ul>
        <li>
          <img src="../assets/auth_tips_01.png" alt="" />
          <span>标准</span>
        </li>
        <li>
          <img src="../assets/auth_tips_02.png" alt="" />
          <span>边框缺失</span>
        </li>
        <li>
          <img src="../assets/auth_tips_03.png" alt="" />
          <span>边框模糊</span>
        </li>
        <li>
          <img src="../assets/auth_tips_04.png" alt="" />
          <span>闪光强烈</span>
        </li>
      </ul>
    </div>

    <van-divider class="divider" />

    <div class="result-wrapper">
      <van-field v-model="form.name" label="姓名" placeholder="请输入姓名" />
      <van-field v-model="form.idNo" label="身份证号" readonly placeholder="请输入身份证号" />
      <van-field v-model="form.bankCode" label="银行卡号" placeholder="请输入银行卡号" @blur="getBankName" />
      <van-field v-model="form.bank" label="所属银行" placeholder="请输入所属银行" />
      <van-field v-model="form.phone" label="手机号码" placeholder="请输入手机号码" />
      <van-field v-model="form.smsCode" center clearable label="短信验证码" maxlength="4" placeholder="请输入短信验证码">
        <template #button>
          <van-button v-if="!counting" class="btn-send" size="small" type="info" plain :disabled="form.phone.length < 11"
            @click="getCode">获取验证码</van-button>
          <span v-if="counting" class="count-down">{{ seconds }}s后可重发</span>
        </template>
      </van-field>
      <div class="tips">
        <!-- <span @click="showAgreement('privacy')">《平台隐私政策协议》</span>
        <span @click="showAgreement('register')">《平台注册服务协议》</span> -->
        <span @click="showAgreement('privacy')">《平台隐私政策协议》</span>
        <span @click="showUserAgreement('register')">《平台注册服务协议》</span>
      </div>
      <button v-if="!isSubmiting" class="btn" :disabled="isDisabled" @click="handleSubmit">
        身份认证
      </button>
      <button v-else class="btn" disabled>提交中</button>
    </div>

    <van-dialog v-model="agreementVisible" confirmButtonText="我已阅读并同意以上条款" width="3.6rem"
      @confirm="handleAgreementConfirm">
      <Agreement :platformId="platformId" :type="type" :agreementVisible="agreementVisible" />
    </van-dialog>
    <van-dialog v-model="UserAgreementVisible" confirmButtonText="我已阅读并同意以上条款" width="3.6rem"
      @confirm="handleUserAgreementConfirm">
      <RegisterAgreement :agreementVisible="UserAgreementVisible" />
    </van-dialog>
  </div>
</template>

<script>
import OSS from "ali-oss";
import Compressor from "compressorjs";
import {
  ActionSheet,
  Dialog,
  Divider,
  Field,
  Notify,
  Toast,
  Uploader,
} from "vant";
import Vue from "vue";
import { mapState } from "vuex";
import Agreement from "../components/Agreement.vue";
import { getOssToken } from "../utils/getOssToken";

Vue.use(Divider)
  .use(Uploader)
  .use(Dialog)
  .use(Notify)
  .use(ActionSheet)
  .use(Field);

export default {
  name: "Authentication",
  components: {
    Agreement,
  },
  data() {
    return {
      image1: null,
      image2: null,
      successFlag1: false,
      successFlag2: false,
      form: {
        idCardFront: "",
        idCardReverse: "",
        name: "",
        idNo: "",
        bank: "",
        bankCode: "",
        phone: "",
        smsCode: "",
        smsCodeId: "",
        issuingAuthority: "",
        issuingDate: "",
        loseDate: "",
      },
      seconds: 60,
      counting: false,
      config: {
        headers: { "Content-Type": "multipart/form-data" },
      },
      uploading: null,
      agreementVisible: true,
      baseUrl: "",
      isSubmiting: false,
      UserAgreementVisible: false,
      clientOSS: {},
      type: "privacy",
      cascaderList: [],
      cascaderShow: false,
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.form.name ||
        !this.form.idNo ||
        !this.form.bank ||
        !this.form.bankCode ||
        !this.form.phone ||
        !this.form.smsCode ||
        !this.image1 ||
        !this.image2 ||
        !this.successFlag1 ||
        !this.successFlag2
      );
    },
    ...mapState({
      userInfo: (state) => state.userInfo,
      platformId: (state) => state.platformId,
    }),
  },
  created() {
    this.baseUrl = process.env.VUE_APP_FTPPATH;
    console.log(this.baseUrl);

    this.getToken();

    this.randomString = this.getRandomString(10);
  },
  methods: {
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    chooseFileFront() {
      this.$refs.cardFront.chooseFile();
    },
    chooseFileBack() {
      this.$refs.cardBack.chooseFile();
    },
    async afterRead1(file) {
      let image = file.file;
      // console.log('before', image.size);
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
        // console.log('after', image.size);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: "照片上传中...",
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format("YYYYMMDD"),
        suffix = image.name.substring(image.name.lastIndexOf(".")),
        path = `wxcert/${date}/${t}-${this.randomString}-01${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          "Content-Disposition": "attachment",
        },
      });
      if (res.res.status === 200) {
        // console.log(res);
        this.image1 = res.url;
        this.form.idCardFront = `/${path}`;
        this.handleOcr(this.image1, "front");
      } else {
        this.uploading.clear();
      }
    },
    async afterRead2(file) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: "照片上传中...",
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format("YYYYMMDD"),
        suffix = image.name.substring(image.name.lastIndexOf(".")),
        path = `wxcert/cert/${date}/${t}-${this.randomString}-02${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          "Content-Disposition": "attachment",
        },
      });
      if (res.res.status === 200) {
        this.image2 = res.url;
        this.form.idCardReverse = `/${path}`;
        // this.handleOcr(this.image2, 'back');
        this.successFlag2 = true;
      } else {
        this.successFlag2 = false;
      }
      this.uploading.clear();
    },
    async handleOcr(url, side) {
      let fd = new FormData();
      fd.append("url", url);
      fd.append("side", side);
      fd.append("flag", 1);
      const data = await this.$axios.post(
        "/system/systemFileController/baIduOcrUrl",
        fd
      );
      if (data.success) {
        if (side == "front") {
          let { 姓名: name, 公民身份号码: idno } = data.data.words_result;
          name && (this.form.name = name.words);
          idno && (this.form.idNo = idno.words);
          this.successFlag1 = true;
        } else {
          let {
            签发机关: issuingAuthority,
            签发日期: issuingDate,
            失效日期: loseDate,
          } = data.data.words_result;
          issuingAuthority &&
            (this.form.issuingAuthority = issuingAuthority.words);
          issuingDate && (this.form.issuingDate = issuingDate.words);
          loseDate &&
            (this.form.loseDate =
              loseDate == "长期--" ? "9999-12-30" : loseDate.words);
          this.successFlag2 = true;
        }
      } else {
        side == "front"
          ? (this.successFlag1 = false)
          : (this.successFlag2 = false);
      }
      this.uploading.clear();
    },
    async getCode() {
      let reg = /^1\d{10}$/;
      if (!reg.test(this.form.phone)) {
        Toast({
          message: "请输入正确格式的手机号码",
          position: "bottom",
        });
        return;
      }
      this.counting = true;
      this.seconds = 60;
      let t1 = setInterval(() => {
        if (this.seconds < 1) {
          this.counting = false;
          clearInterval(t1);
        } else {
          this.seconds--;
        }
      }, 1000);
      const data = await this.$axios.post(
        "/system/wechatController/sendMessag",
        {
          openId: this.userInfo.openId,
          phone: this.form.phone,
          type: 3,
          platformId: this.platformId,
        }
      );
      if (data.success) {
        this.form.smsCodeId = data.data;
      }
    },
    async handleSubmit() {
      this.isSubmiting = true;
      let postData = Object.assign({}, this.form, {
        openId: this.userInfo.openId,
        platformId: this.platformId,
      });
      await this.$axios.post("/tax/wechat/register/login", postData, {
        headers: {
          'Authorization': 'Bearer token'
        }
      }).then((response) => {
        if (response.success) {
          this.$router.push("/profile");
        } else {
          this.isSubmiting = false;
          Toast.fail(response.data.message);
          // this.$router.push("/Register");
        }
      })

    },
    async getUserInfo() {
      const data = await this.$axios.post(
        "/tax/temp/wechat/user/wechat/getUser",
        {
          openId: this.userInfo.openId,
          platformId: this.platformId,
        }
      );
      if (data.success) {
        this.$store.commit("setUserInfo", {
          userInfo: data.data,
        });
        sessionStorage.setItem("userInfo", JSON.stringify(data.data));
        this.$router.push("profile");
      }
      this.isSubmiting = false;
    },
    handleAgreementConfirm() {
      this.agreementVisible = false;
    },
    // getBankName: _.debounce(function () {}, 500),
    getBankName() {
      if (!this.form.bankCode) {
        return;
      }
      let fd = new FormData();
      fd.append("cardNo", this.form.bankCode.trim());
      this.$axios
        .post("/system/systemFileController/getBankName", fd, this.config)
        .then((data) => {
          if (data.success && data.data) {
            this.form.bank = data.data.split("·")[0];
          } else {
            this.form.bank = "";
          }
        })
        .catch((error) => {
          console.log(error);
          this.form.bank = "";
        });
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        maxPos = chars.length,
        pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    showAgreement(type) {
      this.type = type;
      this.agreementVisible = true;
    },
    showUserAgreement(type) {
      this.type = type;
      this.UserAgreementVisible = true;
    },
    handleUserAgreementConfirm() {
      this.UserAgreementVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import "../styles/vars";

.Authentication {
  min-height: 100vh;
  background: #fff;
  padding-bottom: 0.58rem;
  box-sizing: border-box;

  .title {
    padding: 0.1rem 0 0.16rem;
    color: #999;
    font-size: 0.13rem;
    text-align: center;
  }

  .upload-wrapper {
    margin: 0 0.2rem;

    .item {
      position: relative;
      z-index: 10;
      display: inline-block;
      width: 1.8rem;
      height: 1.62rem;
      background: #f4f8fe;
      border-radius: 0.1rem;
      vertical-align: top;

      .van-uploader {
        z-index: -10;
        pointer-events: none;
      }

      &.item-front {
        margin-right: 0.07rem;
      }

      &.item-back {
        margin-left: 0.07rem;
      }

      .inner {
        img {
          display: inline-block;
          width: 1.5rem;
          height: 1rem;
          margin: 0.15rem;
          vertical-align: top;
        }

        p {
          height: 0.32rem;
          line-height: 0.32rem;
          background: $primary;
          border-radius: 0 0 0.1rem 0.1rem;
          color: #fff;
          text-align: center;
        }
      }
    }
  }

  .upload-tips {
    margin: 0.2rem;

    p {
      b {
        display: block;
        margin-bottom: 0.1rem;
      }

      span {
        color: #ff5050;
      }
    }

    ul {
      display: flex;
      margin-top: 0.24rem;

      li {
        flex: 1;
        text-align: center;

        &:not(:last-child) {
          padding-right: 0.05rem;
        }

        img {
          width: 100%;
        }

        span {
          color: #919191;
          font-size: 0.12rem;
        }
      }
    }
  }

  .divider {
    margin: 0;
  }

  .result-wrapper {
    .btn-send {
      color: $primary;
      border-color: $primary;

      &:disabled {
        color: #e1e4ea;
        border-color: #e1e4ea;
        opacity: 1;
      }
    }

    .btn {
      display: block;
      width: 3.8rem;
      height: 0.44rem;
      background-color: $primary;
      margin: 0.46rem auto 0;
      padding: 0;
      border: none;
      border-radius: 0.22rem;
      color: #fff;
      font-size: 0.17rem;
      box-shadow: 0 0.03rem 0.1rem rgba($primary, 0.3);

      &:disabled {
        background-color: $gray;
        box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
      }
    }
  }

  .tips {
    margin: 0.2rem 0;
    text-align: center;

    span {
      color: $primary;
    }
  }
}
</style>
