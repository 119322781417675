<template>
  <div class="Contract">
    <div v-if="!list.length && !loading && finished" class="empty">
      <van-empty description="暂无数据" />
    </div>
    <div v-else class="list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="list">
          <div class="common-list-item" v-for="item in list" :key="item.id">
            <div class="top">
              <div class="date">
                <van-icon name="notes-o" />{{
                  $dayjs(item.createTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD HH:mm:ss')
                }}
              </div>
              <!-- <div class="btn" @click="getContract(item.id)">
                查看详情<van-icon name="arrow" />
              </div> -->
              <div class="btn" @click="item.status == 4 && getContract(item.id)">
                查看详情<van-icon name="arrow" />
              </div>
            </div>
            <div class="bot">
              <p>{{ item.tmpName }}</p>
              <p>{{ item.statusName }}</p>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Empty, NavBar, Toast } from "vant";
import OSS from "ali-oss";
import { getOssToken } from "../utils/getOssToken";
import { fetchList, fetchDetail } from "../utils/apiData"
// import request from "../utils/request";
Vue.use(Empty).use(NavBar).use(Toast);
// import qs from "qs";

export default {
  name: "Contract",
  data() {
    return {
      status: 0,
      list: [],
      pageNo: 1,
      loading: false,
      finished: false,
      limit: 5,
      total: 0,
      totalAmount: {},
      clientOSS: {},
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    this.getToken();
  },
  methods: {
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    onLoad() {
      this.getList();
    },
    // 原来的代码
    // async getList() {
    //   this.finished = false;
    //   this.loading = true;
    //   const data = await request.post(
    //     "/tax/temp/wechat/contract/queryOverSign",
    //     {
    //       openId: this.userInfo.openId,
    //       page: {
    //         page: this.pageNo,
    //       },
    //     }
    //   );
    //   if (data.success) {
    //     const { list, total, pages } = data.data;
    //     this.list = [...this.list, ...list];
    //     this.total = total;
    //     if (this.pageNo < pages) {
    //       this.pageNo++;
    //     } else {
    //       this.finished = true;
    //     }
    //   }
    //   this.loading = false;
    //   this.showLoading = false;
    // },
    // async getList() {
    //   this.finished = false;
    //   this.loading = true;
    //   const data = await request.post(
    //     "/tax/temp/wechat/contract/queryOverSign",
    //     {
    //       openId: this.userInfo.openId,
    //       page: {
    //         page: this.pageNo,
    //       },
    //     }
    //   );
    //   if (data.success) {
    //     const { list, total, pages } = data.data;
    //     this.list = [...this.list, ...list];
    //     this.total = total;
    //     if (this.pageNo < pages) {
    //       this.pageNo++;
    //     } else {
    //       this.finished = true;
    //     }
    //   }
    //   this.loading = false;
    //   this.showLoading = false;
    // },
    async getList() {
      this.finished = false;
      this.loading = true;
      const start = (this.pageNo - 1) * this.limit;
      const length = this.limit;
      fetchList(start, length, "/tax/wechat/user/contract/signed")
        .then((response) => {
          const { data, count, page } = response.data;
          this.list = [...this.list, ...data];
          this.total = count;
          if (this.pageNo < page) {
            this.pageNo++;
          } else {
            this.finished = true;
          }
          this.loading = false;
          this.showLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },


    async getFile(filename) {
      let url = await this.clientOSS.signatureUrl(filename, {
        "content-disposition":
          "attachment; filename=" + encodeURIComponent(filename),
      });
      window.location.href = url;
    },
    async getContract(id) {
      fetchDetail(id, "/tax/wechat/user/contract")
        .then((response) => {
          this.getFile(response.data);
        })
        .catch((error) => {
          console.error(error);
        });

    }
  },
};
</script>

<style lang="scss">
@import "../styles/vars";

.Contract {
  background: #fff;
  min-height: 100vh;

  .list {
    padding: 0.08rem;
  }
}
</style>
