<template>
  <div>
    <van-nav-bar>
      <!-- <template v-slot:right>
        <div style="color: rgb(203, 201, 207)" @click="getRegister">
          去注册 >
        </div>
      </template> -->
    </van-nav-bar>

    <div class="Standard">
      <div class="topBg">
        <p>飞腾兔</p>
        <p>国内领先的人力资源服务器</p>
      </div>
      <div class="result-wrapper">
        <van-field v-model="form.phoneNumber" clearable placeholder="11位手机号" class="custom-field">
          <template #label>
            <div style="display: flex; align-items: center">
              <span>+86</span>
              <van-divider vertical />
            </div>
          </template>
        </van-field>
        <!-- <van-field v-model="form.idNumber" clearable placeholder="6位密码" class="custom-field">
          <template #label>
            <div style="display: flex; align-items: center">
              <span>密码</span>
              <van-divider vertical />
            </div>
          </template>
        </van-field> -->
        <van-field v-model="form.idNumber" clearable placeholder="身份证后六位" class="custom-field">
          <template #label>
            <div style="display: flex; align-items: center">
              <span>密码</span>
              <van-divider vertical />
            </div>
          </template>
        </van-field>
        <div class="btmBtn">
          <van-button type="primary" class="btn" @click="getLogin">
            <span>登</span>
            <span>录</span>
          </van-button>
          <van-button @click="getRegister">身份证登录</van-button>
        </div>
        <div class="bottom-text">
          <!-- 登录代表您已同意<a href="#">用户协议</a>和<a href="#">隐私协议</a> -->
          登录代表您已同意<span @click="showUserAgreement('privacy')">用户协议</span>和 <span
            @click="showAgreement('privacy')">隐私协议</span>
        </div>
      </div>
    </div>
    <van-dialog v-model="agreementVisible" confirmButtonText="我已阅读并同意以上条款" width="3.6rem"
      @confirm="handleAgreementConfirm">
      <Agreement :agreementVisible="agreementVisible" />
    </van-dialog>
    <van-dialog v-model="UserAgreementVisible" confirmButtonText="我已阅读并同意以上条款" width="3.6rem"
      @confirm="handleUserAgreementConfirm">
      <RegisterAgreement :agreementVisible="UserAgreementVisible" />
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Button, Field, Toast, Dialog } from "vant";
import Authentication from "./Authentication.vue";
import Agreement from "../components/Agreement.vue";
import RegisterAgreement from "../components/RegisterAgreement.vue";
import axios from "axios";
Vue.use(Button).use(Field).use(Toast).use(Dialog);
Vue.component("Agreement", Agreement);
Vue.component("RegisterAgreement", RegisterAgreement);
export default {
  name: "Register",
  comments: {
    Authentication,
    Agreement,
  },
  data() {
    return {
      form: {
        phoneNumber: "",
        idNumber: "",
      },
      mobile: "",
      code: "",
      isSubmiting: false,
      agreementVisible: false,
      UserAgreementVisible: false,
      type: "privacy",
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      platformId: (state) => state.platformId,
    }),
  },
  methods: {
    getRegister() {
      this.$router.push("/authentication");
    },
    showAgreement(type) {
      this.type = type;
      this.agreementVisible = true;
    },
    showUserAgreement(type) {
      this.type = type;
      this.UserAgreementVisible = true;
    },
    handleAgreementConfirm() {
      this.agreementVisible = false;
    },
    handleUserAgreementConfirm() {
      this.UserAgreementVisible = false;
    },
    login() {
      if (!this.form.phoneNumber && !this.form.idNumber) {
        // 手机号码和身份证后六位都为空
        alert("请输入手机号码和身份证后六位");
        return false;
      }

      // if (!/^1\d{10}$/.test(this.form.phoneNumber)) {
      //   // 手机号码验证失败
      //   alert("请输入正确的手机号码");
      //   return false;
      // }
      //  之前不包含最后一位X或x
      // if (!/^\d{6}$/.test(this.form.idNumber)) {
      //   // 身份证后六位验证失败
      //   alert("请输入正确的身份证后六位");
      //   return false;
      // }
      //现在包含最后一位X或x
      // if (!/^\d{6}(\d|X|x)$/.test(this.form.idNumber)) {
      //   // 身份证后六位验证失败
      //   alert("请输入正确的身份证后六位");
      //   return false;
      // }

      return true; // 登录验证通过
    },

    async getLogin() {
      if (this.login()) {
        let postData = {
          mobile: this.form.phoneNumber,
          code: this.form.idNumber,
        };
        localStorage.setItem("isLogin", true);
        await this.$axios.post("/tax/wechat/login", postData, {
          headers: {
            'Authorization': 'Bearer token'
          }
        }).then((response) => {
          if (response.success) {
            this.$router.push("/profile");
          } else {
            if (response.data.message == "该手机号未注册,请先注册") {
              // 显示错误提示
              // 登录失败,请重新填写账号或密码
              // this.$router.push("/authentication");
              setTimeout(() => {
                this.$router.push("/authentication");
              }, 1000);
            } else {
              Toast.fail(response.data.message);
            }

          }
        });
      }
    },
  },
  created() {
    sessionStorage.removeItem("sessionId");
  },
};
</script>  

<style lang="scss">
@import "../styles/vars";

.Standard {
  min-height: 100vh;
  background: #fff;
  padding-bottom: 0.58rem;
  box-sizing: border-box;

  .topBg {
    height: 30vh;

    p:first-child {
      // margin-top: 40px;
      padding-top: 15vh;
      font-size: 35px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: CENTER;
      line-height: 26px;
      color: rgb(0, 183, 129);
      // margin-bottom: 20px;
    }

    p:last-child {
      font-size: 14px;
      text-align: CENTER;
      line-height: 26px;
      color: rgb(203, 201, 207);
      margin-top: 20px;
    }
  }

  .middleBg {
    text-align: center;
    color: #999;
  }

  .btmBtn {
    text-align: center;
    font-size: medium;

    button:first-child {
      width: 80%;
      height: 60px;
      margin-top: 80px;
      margin-bottom: 20px;
      border-radius: 4px;
      color: #fff;
      background: rgb(0, 183, 129);
      display: flex;
      justify-content: center;
      align-items: center;

      span:first-child {
        margin-right: 10px;
      }
    }

    button:last-child {
      width: 80%;
      height: 60px;
      border: none;
      background-color: #fff;
      // background: #ff7e3b;
      // border-radius: 4px;
      // border: 1px solid #ff7e3b;
      color: rgb(0, 183, 129);
    }
  }

  .bottom-text {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-size: 12px;
    color: #999;

    // a {
    //   color: rgb(0, 183, 129);
    // }
    span {
      color: rgb(0, 183, 129);
    }
  }

  .upload-wrapper {
    padding: 0 0.2rem;
    text-align: center;

    .item {
      position: relative;
      z-index: 10;
      display: inline-block;
      width: 1.8rem;
      height: 1.62rem;
      background: #f4f8fe;
      border-radius: 0.1rem;
      vertical-align: top;

      .van-uploader {
        z-index: -10;
        pointer-events: none;
      }

      &.item-front {
        margin-right: 0.07rem;
      }

      &.item-back {
        margin-left: 0.07rem;
      }

      .inner {
        img {
          display: inline-block;
          width: 1.5rem;
          height: 1rem;
          margin: 0.15rem;
          vertical-align: top;
        }

        p {
          height: 0.32rem;
          line-height: 0.32rem;
          background: $blue;
          border-radius: 0 0 0.1rem 0.1rem;
          color: #fff;
          text-align: center;
        }
      }
    }
  }

  .upload-tips {
    margin: 0.2rem;

    p {
      b {
        display: block;
        margin-bottom: 0.1rem;
      }

      span {
        color: #ff5050;
      }
    }

    ul {
      display: flex;
      margin-top: 0.24rem;

      li {
        flex: 1;
        text-align: center;

        &:not(:last-child) {
          padding-right: 0.05rem;
        }

        img {
          width: 100%;
        }

        span {
          color: #919191;
          font-size: 0.12rem;
        }
      }
    }
  }

  .divider {
    margin: 0;
  }

  .result-wrapper {
    .btn-send {
      &:disabled {
        color: #e1e4ea;
        border-color: #e1e4ea;
        opacity: 1;
      }
    }

    .btn {
      display: block;
      width: 3.8rem;
      height: 0.44rem;
      background-color: $blue;
      margin: 0.46rem auto 0;
      padding: 0;
      border: none;
      color: #fff;
      font-size: 0.17rem;
      // box-shadow: 0 0.03rem 0.1rem rgba($blue, 0.3);
      box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

      &:disabled {
        background-color: $gray !important;
        // box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
      }
    }

    .custom-field {
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 80%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6%;

      span {
        // width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(0, 183, 129);
        // border-right: 1px solid #000;
        // transform: skewX(45deg);
        // padding-right: 10px;
        position: relative;

        // &::after {
        //   right: -10px;
        //   content: "\\";
        //   position: absolute;
        //   transform: skewX(-45deg);
        // }
        &::after {
          content: "";
          position: absolute;
          top: -14px;
          right: -27px;
          height: 53px;
          width: 1px;
          background-color: #ccc;
          transform: skewX(20deg);
        }
      }
    }

    .custom-field .van-field__body {
      border-bottom: none;
    }
  }

  .tips {
    margin: 0.2rem 0;
    text-align: center;

    span {
      color: $blue;
    }
  }

  .item-image {
    position: relative;
    z-index: 10;
    width: 0.8rem;
    height: 0.8rem;

    .van-uploader {
      z-index: -10;
      pointer-events: none;
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 0.8rem;
      height: 0.8rem;
      background-color: #f7f8fa;

      img {
        width: 100%;
        height: 100%;
      }

      .van-icon {
        color: #dcdee0;
        font-size: 0.24rem;
      }
    }
  }
}
</style>