import Vue from "vue";
import { Toast } from "vant";
import axios from "axios";

Vue.use(Toast);

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

// axios.defaults.headers.common['token'] = sessionStorage.getItem('token');
axios.defaults.headers.common["platformId"] =
  sessionStorage.getItem("platformId");

const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    const success = res.data.success;
    if (success) {
      return res.data;
    } else {
      Toast(res.data.rtnMessage);
      // return Promise.reject(res.data);
      return res.data;
    }
  },
  // (error) => {
  //   if (String(error).includes("timeout")) {
  //     // Toast.fail('请求超时');
  //     return Promise.reject(error);
  //   } else {
  //     return Promise.reject(error);
  //   }

  // }
  (error) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          Toast("错误请求");
          break;
        case 401:
          Toast("未授权，请登录");
          break;
        case 403:
          Toast("拒绝访问");
          break;
        case 404:
          Toast("请求地址出错");
          break;
        case 408:
          Toast("请求超时");
          break;
        case 417:
          Toast("预期失败");
          break;
        case 500:
          Toast("服务器内部错误");
          break;
        case 501:
          Toast("服务未实现");
          break;
        case 502:
          Toast("网关错误");
          break;
        case 503:
          Toast("服务不可用");
          break;
        case 504:
          Toast("网关超时");
          break;
        case 505:
          Toast("HTTP版本不受支持");
          break;
        default:
          Toast("其它状态码: " + error.response.status);
          break;
      }
    } else if (String(error).includes("timeout")) {
      Toast("请求超时");
    } else {
      Toast("发生错误");
    }
    return Promise.reject(error);
  }
);

export default service;
