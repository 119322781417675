import Vue from "vue";
import Router from "vue-router";
import Home from "../pages/Home.vue";
import Loading from "../pages/Loading.vue";
import Authentication from "../pages/Authentication.vue";
import Profile from "../pages/Profile.vue";
import MyInfo from "../pages/MyInfo.vue";
import Contract from "../pages/Contract.vue";
import Salary from "../pages/Salary.vue";
import SalaryDetail from "../pages/SalaryDetail.vue";
import Social from "../pages/Social.vue";
import SocialDetail from "../pages/SocialDetail.vue";
import Sign from "../pages/Sign.vue";
import SignDetail from "../pages/SignDetail.vue";
import Register from "../pages/Register.vue";
Vue.use(Router);

const router = new Router({
  routes: [
    // {
    //   path: '/',
    //   redirect: '/home',
    // },
    // {
    //   path: "/",
    //   name: "home",
    //   component: Home,
    // },
    // {
    //   path: "/",
    //   name: "register",
    //   component: Register,
    // },
    {
      path: "/",
      name: "profile",
      redirect: "/profile",
      meta: {
        needLogin: true, //需要加校检判断的路由
      },
    },
    {
      path: "/Register",
      name: "register",
      component: Register,
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        needLogin: true, //需要加校检判断的路由
      },
    },
    {
      path: "/authentication",
      name: "authentication",
      component: Authentication,
      // meta: {
      //   needLogin: true, //需要加校检判断的路由
      // },
    },
    {
      path: "/loading",
      name: "loading",
      component: Loading,
      meta: {
        needLogin: true, //需要加校检判断的路由
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        needLogin: true, //需要加校检判断的路由,
        title: "我的",
      },
    },
    {
      path: "/myInfo",
      name: "myInfo",
      component: MyInfo,
      meta: {
        needLogin: true, //需要加校检判断的路由,
        title: "个人信息",
      },
    },
    {
      path: "/contract",
      name: "contract",
      component: Contract,
      meta: {
        needLogin: true, //需要加校检判断的路由,
        title: "合同管理",
      },
    },
    {
      path: "/salary",
      name: "salary",
      component: Salary,
      meta: {
        needLogin: true, //需要加校检判断的路由,
        title: "工资条",
      },
    },
    {
      path: "/salaryDetail",
      name: "salaryDetail",
      component: SalaryDetail,
      meta: {
        needLogin: true, //需要加校检判断的路由,
        title: "工资条",
      },
    },
    {
      path: "/social",
      name: "social",
      component: Social,
      meta: {
        needLogin: true, //需要加校检判断的路由,
        title: "社保公积金",
      },
    },
    {
      path: "/socialDetail",
      name: "socialDetail",
      component: SocialDetail,
      meta: {
        needLogin: true, //需要加校检判断的路由,
        title: "社保公积金",
      },
    },
    {
      path: "/sign",
      name: "sign",
      component: Sign,
      meta: {
        needLogin: true, //需要加校检判断的路由,
        title: "签约管理",
      },
    },
    {
      path: "/signDetail",
      name: "signDetail",
      component: SignDetail,
      meta: {
        needLogin: true, //需要加校检判断的路由,
        title: "签约管理",
      },
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   let t = to.meta.title;
//   document.title = t ? t : "HRO";
//   next();
// })

router.beforeEach((to, from, next) => {
  let t = to.meta.title;
  document.title = t ? t : "HRO";
  if (to.meta.needLogin) {
    if (sessionStorage.getItem("sessionId")) {
      next();
      console.log("已登录");
    } else {
      next({
        name: "register",
      });
    }
    // console.log("未登录1");
  } else {
    next();
    // console.log("未登录2");
  }
});

const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
