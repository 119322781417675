<template>
  <div class="Loading">
    <van-loading />
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Loading } from "vant";
import getQueryParams from "../utils/getQueryParams";

Vue.use(Loading);

export default {
  name: "Loading",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      platformId: (state) => state.platformId,
    }),
  },
  created() {
    this.getWxAppId();
  },
  methods: {
    async checkOpenId(code) {
      const data = await this.$axios.post("/tax/temp/wechat/user/checkOpenId", {
        code,
        platformId: this.platformId,
      });
      if (data.success) {
        console.log(data.data);
        this.$store.commit("setUserInfo", {
          userInfo: data.data,
        });
        sessionStorage.setItem("userInfo", JSON.stringify(data.data));
        if (!data.data.idNoStatus) {
          this.$router.push("authentication");
        } else {
          this.$router.push("profile");
        }
      }
    },
    async getWxAppId() {
      const data = await this.$axios.post(
        "/system/wechatController/getPlatform",
        {
          platformId: this.platformId,
        }
      );
      if (data.success) {
        console.log("data.data.wxAppId", data.data.wxAppId);
        this.getCode(data.data.wxAppId);
      }
    },
    getCode(appid) {
      console.log("getcode");
      let code = getQueryParams(window.location.search)["code"],
        redirect = encodeURIComponent(
          window.location.origin + process.env.VUE_APP_PUBLICPATH
        ),
        url = "";
      console.log("code", code);
      if (code == null || code === "") {
        url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=snsapi_base#wechat_redirect`;
        console.log("url", url);
        window.location.href = url;
      } else {
        this.checkOpenId(code);
      }
    },
    async addUserCar(postData) {
      let fd = new FormData();
      for (const key in postData) {
        fd.append(key, postData[key]);
      }
      const data = await this.$axios.post(
        "/tax/temp/wechat/user/carAddUserInfo",
        fd
      );
      if (data.success) {
        window.location.href = data.data;
      }
    },
    async getUrl(accountId, taskId) {
      let fd = new FormData();
      fd.append("accountId", accountId);
      fd.append("taskId", taskId);
      const data = await this.$axios.post(
        "/tax/temp/wechatController/doEleSign",
        fd
      );
      if (data.success) {
        if (data.data.shortUrl) {
          window.location.href = data.data.shortUrl;
        } else {
          this.$router.push("profile");
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/vars";

.Loading {
  height: 100vh;
  background: #fff;

  .van-loading {
    top: 50%;
    left: 50%;
    transform: translate(-0.32rem, -0.32rem);
  }

  .van-loading__spinner {
    width: 0.64rem;
    height: 0.64rem;
    color: $primary;
  }
}
</style>
