<!-- <template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"> </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {},
};
</script> -->

<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"> </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Dialog } from "vant";
Vue.use(Dialog);

export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },

  created() {
  },
};
</script>