<template>
  <div class="Social">
    <div v-if="!list.length && !loading && finished" class="empty">
      <van-empty description="暂无数据" />
    </div>
    <div v-else class="list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="list">
          <div class="common-list-item" v-for="item in list" :key="item.id">
            <div class="top">
              <div class="date">
                <van-icon name="notes-o" />{{
                  $dayjs(item.createTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD HH:mm:ss')
                }}
              </div>
              <div class="btn" @click="toDetail(item)">
                查看详情<van-icon name="arrow" />
              </div>
            </div>
            <div class="bot">
              <p class="flex-para">
                <span>{{ item.month }}</span><span>{{ formatAmount(item.sumPer) }} 元</span>
              </p>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Empty, NavBar, Toast } from "vant";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import formatAmount from "../utils/formatAmount";
import { fetchList } from "../utils/apiData"
Vue.use(Empty).use(NavBar).use(Toast);
dayjs.extend(customParseFormat);

export default {
  name: "Social",
  data() {
    return {
      formatAmount,
      // status: 0,
      publishStatus: 0,
      list: [],
      pageNo: 1,
      loading: false,
      finished: false,
      total: 0,
      limit: 5,
      totalAmount: {},
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  created() { },
  methods: {
    onLoad() {
      this.getTaskList();
    },
    async getTaskList() {
      this.finished = false;
      this.loading = true;
      const start = (this.pageNo - 1) * this.limit;
      const length = this.limit;
      fetchList(start, length, "/tax/wechat/user/socialfund")
        .then((response) => {
          const { data, count, page } = response.data;
          this.list = [...this.list, ...data];
          this.total = count;
          if (this.pageNo < page) {
            this.pageNo++;
          } else {
            this.finished = true;
          }
          this.loading = false;
          this.showLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toDetail(obj) {
      sessionStorage.setItem("socialDetail", JSON.stringify(obj));
      this.$router.push("socialDetail");
    },
  },
};
</script>

<style lang="scss">
@import "../styles/vars";

.Social {
  background: #fff;
  min-height: 100vh;

  .list {
    padding: 0.08rem;
  }

  .flex-para {
    display: flex;
    justify-content: space-between;
  }
}
</style>
