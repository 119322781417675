<template>
  <div class="Salary">
    <div v-if="!list.length && !loading && finished" class="empty">
      <van-empty description="暂无数据" />
    </div>
    <div v-else class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="list">
          <div
            class="common-list-item"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="top">
              <div class="date">
                <!-- <van-icon name="notes-o" />{{
                  $dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
                }} -->
                <van-icon name="notes-o" />{{
                  $dayjs(item.createTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD HH:mm:ss')
                }}
              </div>
              <div class="btn" @click="toDetail(item)">
                查看详情<van-icon name="arrow" />
              </div>
            </div>
            <div class="bot">
              <p>{{ $dayjs(item.month).format("YYYY年M月收入") }}</p>
              <p>感谢您的辛勤工作，请及时查看工资条</p>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Empty, NavBar, Toast } from "vant";
Vue.use(Empty).use(NavBar).use(Toast);
import request from "../utils/request";
import {fetchList} from "../utils/apiData"
export default {
  name: "Salary",
  data() {
    return {
      // status: 0,
      publishStatus: 0,
      list: [],
      pageNo: 1,
      loading: false,
      finished: false,
      total: 0,
      limit: 5,
      totalAmount: {},
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {},
  methods: {
    onLoad() {
      this.getList();
    },
    // async getList() {
    //   this.finished = false;
    //   this.loading = true;
    //   const data = await request.post(
    //     "/tax/temp/wechatController/wechat/getSalary",
    //     {
    //       openId: this.userInfo.openId,
    //       page: {
    //         page: this.pageNo,
    //       },
    //     }
    //   );
    //   if (data.success) {
    //     const { list, total, pages } = data.data;
    //     this.list = [...this.list, ...list];
    //     this.total = total;
    //     if (this.pageNo < pages) {
    //       this.pageNo++;
    //     } else {
    //       this.finished = true;
    //     }
    //   }
    //   this.loading = false;
    //   this.showLoading = false;
    // },
    async getList() {
      this.finished = false;
      this.loading = true;
      const start = (this.pageNo - 1) * this.limit;
      const length = this.limit;
      console.log(this.userInfo);
      fetchList(start, length, "/tax/wechat/user/salary")
        .then((response) => {
          const { data, count, page } = response.data;
          this.list = [...this.list, ...data];
          this.total = count;
          if (this.pageNo < page) {
            this.pageNo++;
          } else {
            this.finished = true;
          }
          this.loading = false;
          this.showLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toDetail(obj) {
      sessionStorage.setItem("salaryDetail", JSON.stringify(obj));
      this.$router.push("salaryDetail");
    },
  },
};
</script>

<style lang="scss">
@import "../styles/vars";

.Salary {
  background: #fff;
  min-height: 100vh;

  .list {
    padding: 0.08rem;
  }
}
</style>
