<template>
  <div class="SignDetail">
    <div class="title">签约信息</div>
    <van-cell-group :border="false">
      <van-field
        label="电签编号"
        v-model="detail.orderNo"
        input-align="right"
        disabled
      />
      <van-field
        label="模版编号"
        v-model="detail.positionNo"
        input-align="right"
        disabled
      />
      <van-field
        label="姓名"
        v-model="detail.name"
        input-align="right"
        disabled
      />
      <van-field
        label="身份证号"
        v-model="detail.idNo"
        input-align="right"
        disabled
      />
      <van-field
        label="模版名称"
        v-model="detail.tmpName"
        input-align="right"
        disabled
      />
      <van-field
        label="客户名"
        v-model="detail.entName"
        input-align="right"
        disabled
      />
      <van-field
        label="公司名"
        v-model="detail.taxCenterName"
        input-align="right"
        disabled
      />
    </van-cell-group>

    <div class="title">用户信息</div>
    <van-cell-group :border="false">
      <van-field
        v-for="item in list"
        :key="item.id"
        :label="item.subName"
        v-model="item.defValue"
        :required="item.required ? true : false"
        input-align="right"
      />
    </van-cell-group>
    <div class="btn">
      <van-button
        type="primary"
        :loading="isLoading"
        @click="handleValidate"
        block
        >确定</van-button
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Field, Toast } from "vant";

Vue.use(Field);

export default {
  name: "SignDetail",
  data() {
    return {
      list: [],
      detail: {},
      isLoading: false,
    };
  },
  created() {
    this.detail = JSON.parse(sessionStorage.getItem("signDetail"));
    this.getInfo(this.detail);
    console.log(this.$route.query);
  },
  methods: {
    async getInfo(detail) {
      // 原
      // const { idNo, positionNo } = this.detail;
      // 现在
      const { idNo, positionNo } = detail;

      const data = await this.$axios.post(
        "/tax/temp/wechat/contract/queryTrendsUserInfo",
        {
          idNo,
          positionNo,
        }
      );
      if (data.success) {
        this.list = data.data;
      }
    },
    handleValidate() {
      let obj = {};
      for (const item of this.list) {
        obj[item.subKey] = item.defValue;
        if (item.required && !item.defValue) {
          Toast("请完善用户信息");
          return;
        }
      }
      this.handleSubmit(obj);
      console.log(obj);
    },
    async handleSubmit(obj) {
      this.isLoading = true;
      // 原
      // const { idNo, positionNo } = this.detail;
      // 现在
      const signDetail = JSON.parse(sessionStorage.getItem("signDetail"));
      const { idNo, positionNo } = signDetail;
      let postData = { idNo, positionNo, ...obj };
      const data = await this.$axios.post(
        "/tax/temp/wechat/contract/saveTrendsUserInfo",
        postData
      );
      if (data.success) {
        window.location.href = data.data;
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/vars";

.SignDetail {
  min-height: 100vh;
  background: #fff;

  .title {
    line-height: 0.2rem;
    padding: 0.22rem 0.16rem 0.08rem;
    color: #939aa3;
    font-size: 0.14rem;
  }

  .van-cell {
    line-height: 0.22rem;
    padding: 0.16rem;

    &::after {
      right: 0;
    }

    .van-field__label {
      color: $font;
      font-size: 0.16rem;

      em {
        display: inline-block;
        margin-left: 0.06rem;
        color: $red;
        font-style: normal;
        vertical-align: top;
      }
    }

    .van-field__control {
      color: #86909c;
      font-size: 0.14rem;
    }
  }

  .btn {
    padding: 0.32rem 0.16rem;

    .van-button {
      background: $primary;
    }
  }
}
</style>
