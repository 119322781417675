import Vue from "vue";
import { Toast } from "vant";
import axios from "axios";
// import qs from "qs";

Vue.use(Toast);

// axios.defaults.headers["Content-Type"] =
//   "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.headers["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers.common["appid"] = 3;
const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    // const sessionId = localStorage.getItem("sessionId");
    const sessionId = sessionStorage.getItem("sessionId");
    if (sessionId) {
      config.headers.ssessionid = sessionId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  // 原
  // (res) => {
  //   const success = res.data.success;
  //   if (success) {
  //     if (res.config.method !== 'get') {
  //       const newSessionId = res.headers["ssessionid"];
  //       if (newSessionId) {
  //         sessionStorage.setItem("sessionId", newSessionId);
  //       }
  //     }  
  //     return res.data;
  //   } else {
  //     if (res?.data?.message !== undefined) {
  //     Toast.fail(res?.data?.message);
  //     }
  //     res?.data?.rtnMessage && Toast.fail(res?.data?.rtnMessage);
  //     // return res.data;
  //     return res;
  //   }
  // },
  (res) => {
    const success = res.data.success;
    if (success) {
      if (res.config.method !== 'get') {
        const newSessionId = res.headers["ssessionid"];
        if (newSessionId) {
          sessionStorage.setItem("sessionId", newSessionId);
        }
      }
      return res.data;
    } else {
      if (res?.data?.message !== undefined) {
        Toast.fail(res?.data?.message);
      }
      res?.data?.rtnMessage && Toast.fail(res?.data?.rtnMessage);
      // return res.data;
      return res;
    }
  },
  // res => {
  //   // 处理成功响应
  //   if(res.data.success) {  
  //     return res    
  //   } else {
  //     // 处理错误情况
  //     Toast(res.data.message)
  //     // return {
  //     //   data: res.data,
  //     //   headers: res.headers
  //     // }
  //   }
  // },

  (error) => {
    console.log(error);
    if (String(error).includes("timeout")) {
      // Toast.fail('请求超时');
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;