<template>
  <div class="SocialDetail">
    <van-field label="姓名" v-model="detail.name" readonly />
    <van-field label="身份证号" v-model="detail.idNo" readonly />
    <van-field label="日期" v-model="detail.month" readonly />
    <van-field label="养老个人金额" readonly>
      <template #input>
        <span>{{ formatAmount(detail.pensionPer) }} 元</span>
      </template>
    </van-field>
    <van-field label="医疗个人金额" readonly>
      <template #input>
        <span>{{ formatAmount(detail.healthPer) }} 元</span>
      </template>
    </van-field>
    <van-field label="失业个人金额" readonly>
      <template #input>
        <span>{{ formatAmount(detail.losePer) }} 元</span>
      </template>
    </van-field>
    <van-field label="大病个人金额" readonly>
      <template #input>
        <span>{{ formatAmount(detail.illnessPer) }} 元</span>
      </template>
    </van-field>
    <van-field label="补充医疗个人金额" readonly>
      <template #input>
        <span>{{ formatAmount(detail.plugHealthPer) }} 元</span>
      </template>
    </van-field>
    <van-field label="其它个人金额" readonly>
      <template #input>
        <span>{{ formatAmount(detail.otherPer) }} 元</span>
      </template>
    </van-field>
    <van-field label="个人社保小计" readonly>
      <template #input>
        <span>{{ formatAmount(detail.socialSumPer) }} 元</span>
      </template>
    </van-field>
    <van-field label="社保缴纳城市" v-model="detail.socialCity" readonly />
    <van-field label="公积金缴纳城市" v-model="detail.houseCity" readonly />
    <van-field label="公积金个人金额" readonly>
      <template #input>
        <span>{{ formatAmount(detail.housePer) }} 元</span>
      </template>
    </van-field>
    <van-field label="个人合计" readonly>
      <template #input>
        <span>{{ formatAmount(detail.sumPer) }} 元</span>
      </template>
    </van-field>
    <van-field label="备注（个人）" v-model="detail.remarkPer" readonly />
  </div>
</template>

<script>
import Vue from 'vue';
import { Field } from 'vant';
import formatAmount from '../utils/formatAmount';

Vue.use(Field);

export default {
  name: 'SocialDetail',
  data() {
    return {
      formatAmount,
      detail: {},
    };
  },
  created() {
    let obj = JSON.parse(sessionStorage.getItem('socialDetail'));
    this.detail = obj;
  },
  methods: {},
};
</script>

<style lang="scss">
@import '../styles/vars';

.SocialDetail {
  min-height: 100vh;
  background: #fff;
  padding-bottom: 0.24rem;

  .van-cell {
    padding: 0.16rem;
  }

  .van-field__label {
    width: auto;
    color: #4e5969;
    font-size: 0.16rem;
  }

  .van-field__control {
    justify-content: flex-end;
    font-size: 0.16rem;
    font-weight: 500;
    text-align: right;
  }
}
</style>
