<template>
  <div class="Profile">
    <div class="user">
      <div class="avatar">
        <img src="../assets/个人默认头像.png" alt="" />
        <!-- <span>HI,{{ userInfo.phone }} </span> -->
        <span>HI,{{ name }} </span>
      </div>
      <div class="info">
        <p>{{ userInfo.name }}</p>
        <p :class="['status', 'status-' + userInfo.idNoStatus]">
          {{ statusObj[userInfo.idNoStatus] }}
        </p>
        <div class="btns">
          <div class="btn" @click="$router.push('myInfo')">完善资料</div>
          <div class="btn" @click="getResetPwd">修改密码</div>
        </div>
      </div>
    </div>

    <div class="entry">
      <van-cell-group :border="false">
        <van-cell is-link @click="$router.push('contract')">
          <template #title>
            <i class="icon icon-contract"></i>
            <span>合同管理</span>
          </template>
        </van-cell>
        <van-cell is-link @click="$router.push('salary')">
          <template #title>
            <i class="icon icon-salary"></i>
            <span>工资条</span>
          </template>
        </van-cell>
        <van-cell is-link @click="$router.push('social')">
          <template #title>
            <i class="icon icon-social"></i>
            <span>社保公积金</span>
          </template>
        </van-cell>
        <van-cell is-link @click="$router.push('sign')">
          <template #title>
            <i class="icon icon-sign"></i>
            <span>签约管理</span>
          </template>
          <span style="
              background-color: red;
              border-radius: 50%;
              color: white;
              display: inline-block;
              width: 20px;
              height: 20px;
              text-align: center;
              line-height: 20px;
            " v-if="showBadge">{{ notSignContractCount }}</span>
        </van-cell>
      </van-cell-group>
    </div>
    <!-- 
    <NavBar :activeNav="'profile'" /> -->
    <van-dialog v-model="resetPwdVisible" title="修改密码" @confirm="resetConfirm" @cancel="resetPwdVisible = false"
      :beforeClose="onBeforeClose" show-cancel-button>
      <van-form ref="formData">
        <van-field v-model="formData.password" required label="原密码" input-align="right" placeholder="请输入原密码"
          :rules="[{ required: true, message: '', trigger: 'onBlur' }]" />
        <van-field v-model="formData.newPassword" required label="新密码" input-align="right" placeholder="请输入新密码"
          :rules="[{ required: true, message: '', trigger: 'onBlur' }]" />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Cell, CellGroup, Toast, Dialog, Button ,Form} from "vant";
// import NavBar from "../components/NavBar.vue";
import formatAmount from "../utils/formatAmount";

Vue.use(Cell).use(CellGroup).use(Toast).use(Dialog).use(Button).use(Form);

export default {
  name: "Profile",
  components: {
    // NavBar,
  },
  data() {
    return {
      formatAmount,
      statusObj: {
        0: "未认证",
        1: "已认证",
        2: "认证失败",
      },
      formData: {
        password: "",
        newPassword: "",
      },
      name: null,
      notSignContractCount: 0,
      showBadge: false,
      resetPwdVisible: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    toMyTaskList(status) {
      this.$router.push({
        name: "myTaskList",
        params: { status },
      });
    },
    toMyUnsignedList() {
      this.$router.push("myUnsignedList");
    },
    toMySignedList() {
      this.$router.push("mySignedList");
    },
    toAuth() {
      this.$router.push("authentication");
    },
    showTips() {
      Toast("功能开发中，敬请期待");
    },
    getResetPwd() {
      this.resetPwdVisible = true;
      this.formData = {};
    },
    // 取消默认关闭弹框事件
    onBeforeClose(action, done) {
      return done(false)
    },
    resetConfirm() {
      this.$refs.formData.validate().then(async () => {
        this.getResetConfirm();
        // this.resetPwdVisible = false // 表单验证通过后手动关闭弹框
      }).catch(() => {

      })
    },
    async getResetConfirm() {
      let postData = {
        password: this.formData.password,
        newPassword: this.formData.newPassword,
      };
      await this.$axios.put("/tax/wechat/user/pwd/update", postData).then((response) => {
        if (response.success) {
          this.resetPwdVisible = false;
          Toast("密码修改成功");
          this.formData = {};
        }
      });
    },
    async getUserInfo() {
      const data = await this.$axios.get("/tax/wechat/user/info");
      if (data.success) {
        if (data.data !== null) {
          if (data.data.notSignContractCount > 0) {
            this.showBadge = true;
            this.notSignContractCount = data.data.notSignContractCount;
          } else {
            this.showBadge = false;
          }
          this.name = data.data.name;
        } else {
          this.showBadge = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/vars";

.Profile {
  padding-bottom: 0.58rem;

  .user {
    height: 4.03rem;
    background: url("../assets/profile_bg.png") no-repeat 0 0 / 100%;
    padding: 0.24rem 0 0 0.22rem;
    box-sizing: border-box;

    .avatar {
      img {
        display: inline-block;
        width: 0.51rem;
        height: 0.51rem;
        vertical-align: top;
      }

      span {
        height: 0.51rem;
        line-height: 0.51rem;
        margin-left: 0.12rem;
        font-weight: 500;
      }
    }

    .info {
      margin-top: 0.52rem;

      p {
        font-weight: 500;

        &+p {
          margin-top: 0.08rem;
        }
      }

      .status {
        font-size: 0.12rem;

        &.status-0,
        &.status-2 {
          color: $red;
        }

        &.status-1 {
          color: $green;
        }
      }

      .btn {
        width: 0.82rem;
        height: 0.28rem;
        line-height: 0.28rem;
        background: $primary;
        margin-top: 0.16rem;
        color: #fff;
        font-size: 0.12rem;
        text-align: center;
        border-radius: 0.02rem;
      }
    }

    .btns {
      display: flex;
      div:first-child{
        margin-right: 0.16rem;
      }
      cursor: pointer;
    }
  }

  .statistics {
    display: flex;
    align-items: center;
    background: #fff;
    margin: -0.42rem 0.16rem 0.16rem;
    padding: 0.16rem;
    border-radius: 0.1rem;

    .item {
      flex: 1;
      position: relative;
      text-align: center;

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 80%;
        background: #d9d9d9;
        transform: scaleX(0.5) translateY(-50%);
      }

      b {
        display: block;
        color: $primary;
        font-size: 0.2rem;
      }

      span {
        display: block;
        margin-top: 0.12rem;
      }
    }
  }

  .entry {
    margin-top: -0.27rem;

    .van-cell {
      padding: 0.15rem 0.2rem;
    }

    .van-cell__title {
      .icon {
        display: inline-block;
        width: 0.24rem;
        height: 0.24rem;
        vertical-align: top;

        &.icon-contract {
          background: url("../assets/icon_contract.png") no-repeat 0 0 / contain;
        }

        &.icon-social {
          background: url("../assets/icon_social.png") no-repeat 0 0 / contain;
        }

        &.icon-salary {
          background: url("../assets/icon_salary.png") no-repeat 0 0 / contain;
        }

        &.icon-sign {
          background: url("../assets/icon_sign.png") no-repeat 0 0 / contain;
        }
      }

      span {
        display: inline-block;
        height: 0.24rem;
        line-height: 0.24rem;
        margin-left: 0.1rem;
        font-size: 0.16rem;
        vertical-align: top;
      }

      a {
        position: relative;
        display: block;
        width: 100%;
        color: $font;

        .van-icon-arrow {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: 0;
          color: #969799;
        }
      }
    }

    .tag {
      display: inline-block;
      height: 0.22rem;
      line-height: 0.22rem;
      background-image: linear-gradient(45deg, $primary 2%, #1f6af2 90%);
      padding: 0 0.08rem;
      border-radius: 0.06rem;
      border-bottom-left-radius: 0;
      color: #fff;
      font-size: 0.12rem;
      font-weight: 500;
      vertical-align: top;
      transform: scale(0.7, 0.7);

      &.error {
        background-image: linear-gradient(45deg, red 2%, red 90%);
      }
    }
  }
}
</style>
